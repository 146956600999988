'use client';

import RowField from '@/bundles/core/components/client/row-field';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Stack } from '@mui/system';
import RouterLink from 'next/link';
import React, { FC } from 'react';

import paths from '../../../paths';
import { ProductFormType, useProductForm } from './hooks/useProductForm';

const ProductForm: FC<ProductFormType> = (props) => {
  const { fields, handleSubmit, onSubmit, control, form } = useProductForm(props);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid md={12} xs={12}>
          <Card elevation={0}>
            <CardContent>
              <Stack spacing={2}>
                {fields.map((field, idx) => (
                  <RowField key={idx} field={field as never} control={control as never} form={form as never} />
                ))}
              </Stack>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button color="secondary" component={RouterLink} href={paths.index}>Cancelar</Button>
              {/*<Button type="submit" variant="outlined" onClick={form.reset}>Reset</Button>*/}
              <Button disabled={form?.formState?.isSubmitting} type="submit" variant="contained">
                {form?.formState?.isSubmitting ? 'Guardando' : 'Guardar'}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductForm;
