import { useFlash } from '@lib/flash';
import LOG from '@lib/logger';
import { objEmpty } from '@lib/utils';
import { useRouter } from 'next/navigation';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { PostFormProps } from '@/bundles/blog/components/client/post-form';
import { IPost, Post } from '@/bundles/blog/model/post';
import EntityManager from '@/bundles/core/services/entity-manager';

import useFormFields, { FormValuesProps } from './useFormField';

export type usePostFormProps = {} & PostFormProps;

export default function usePostForm(props: usePostFormProps) {
  const router = useRouter();
  const flash = useFlash();
  const { fields, defaultValues, resolver } = useFormFields(props);
  const { control, handleSubmit, ...form } = useForm<FormValuesProps>({ defaultValues, resolver });

  const postManager = useMemo(() => new EntityManager<IPost>('/', '/api/proxy/posts'), []);

  const onSubmit = async (values: FormValuesProps) => {
    try {
      const newPost = new Post(values).toFormData();
      LOG.debug({ msg: 'onSubmit', values, newPost });
      const { error, data } = await postManager.save({ ...newPost, id: props.post?.id });
      LOG.debug({ msg: 'onSubmit:posts', newPost, data, error });
      if (error) {
        flash.toast('No pudimos guardar la publicación', 'error', error);
        return;
      }
      flash.toast('Publicación guardado', 'success');
      router.refresh();
    } catch (error) {
      LOG.warn({ msg: 'onSubmit', error });
      flash.toast('No pudimos guardar la publicación', 'error', (error as Error).message);
    }
  };

  useEffect(() => {
    if (!objEmpty(form.formState.errors)) {
      const error = JSON.stringify(form.formState.errors, null, 2);
      flash.toast('Hay errores en el formulario', 'error', error);
      LOG.warn({ msg: 'usePostForm::formState.errors', errors: form.formState.errors });
    }
  }, [form.formState.errors]);

  return { onSubmit, handleSubmit, control, form, fields };
}
