import { z } from 'zod';

const schemas = z.object({
  name: z.string(),
  cuit: z.string().max(255).optional(),
  logo: z.object({ url: z.string() }).optional(),
  dataFiscal: z.object({ url: z.string(), code: z.string(), qr: z.string() }).optional(),
});

export default schemas;
