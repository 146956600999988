import InputField from "@/bundles/core/components/client/input-field";
import InputGallery from "@/bundles/core/components/client/input-gallery";
import InputSelect from "@/bundles/core/components/client/input-select";
import InputTextEditor from "@/bundles/core/components/client/input-text-editor";
import { IFormField } from "@/bundles/core/types";
import schemas from "@/bundles/general/components/client/shop-form/schema";
import { Shop } from "@/bundles/general/model/shop";
import { zodResolver } from "@hookform/resolvers/zod";
import LOG from "@lib/logger";
import { useMemo } from "react";
import { z } from "zod";

import { ShopFormProps } from "../index";

export type useFormFieldsProps = {} & ShopFormProps;

export type FormValuesProps = z.infer<typeof schemas>;

export default function useFormFields(props: useFormFieldsProps) {
  const fields = useMemo(() => {
    const inputFields: Array<IFormField> = [
      {
        name: "title",
        label: "Título",
        type: "text",
        Component: InputField
      },
      {
        name: "description",
        label: "Descripción",
        type: "text",
        multiline: true,
        Component: InputField
      },
      [{
        name: "keywords",
        label: "Palabras clave",
        type: "text",
        Component: InputField
      },
        {
          name: "theme",
          label: "Apariencia",
          type: "text",
          Component: InputSelect,
          options: [
            { value: "verano-light", label: "Verano Light" },
            { value: "verano-dark", label: "Verano Dark" },
            { value: "invierno-light", label: "Invierno Light" },
            { value: "invierno-dark", label: "Invierno Dark" }
          ]
        }],
      {
        name: "favicon",
        label: "URL del favicon",
        type: "text",
        Component: InputGallery
      },
      {
        name: "banners",
        label: "Banners",
        type: "text",
        multiple: true,
        Component: InputGallery
      },
      [
        {
          name: "sendgridApiKey",
          label: "API Key de Sendgrid",
          type: "text",
          Component: InputField
        },
        {
          name: "sendgridFrom",
          label: "Email de envío de Sendgrid",
          type: "text",
          Component: InputField
        }
      ],
      [
        {
          name: "googleClientId",
          label: "ID de cliente de Google",
          type: "text",
          Component: InputField
        },
        {
          name: "googleClientSecret",
          label: "Secreto de cliente de Google",
          type: "text",
          Component: InputField
        }
      ],
      {
        name: "facebookPixelId",
        label: "ID de Facebook Pixel",
        type: "text",
        Component: InputField
      },
      {
        name: "dangerousScriptHeader",
        label: "Script peligroso (Header)",
        type: "text",
        multiline: true,
        Component: InputTextEditor
      },
      {
        name: "dangerousScriptFooter",
        label: "Script peligroso (Footer)",
        type: "text",
        multiline: true,
        Component: InputTextEditor
      },
    ];
    return inputFields;
  }, [props.shop]);

  const defaultValues = useMemo(() => {
    const defValues = (props.shop ? new Shop(props.shop as Record<string, unknown>) : new Shop({})) as FormValuesProps;
    LOG.debug({ msg: "defaultValues", defValues, shop: props.shop });
    return defValues;
  }, [props.shop]);

  const resolver = zodResolver(schemas);

  return { fields, defaultValues, resolver };
}
