import { zodResolver } from '@hookform/resolvers/zod';
import LOG from '@lib/logger';
import { useMemo } from 'react';
import { z } from 'zod';

import InputField from '@/bundles/core/components/client/input-field';
import InputGallery from '@/bundles/core/components/client/input-gallery';
import { IFormField } from '@/bundles/core/types';
import { Paymethod } from '@/bundles/general/model/paymethod';

import { PaymethodFormProps } from '../index';
import schemas from '../schema';

export type usePaymentFormProps = {} & PaymethodFormProps;

export type FormValuesProps = z.infer<typeof schemas>;

export default function useFormFields(props: usePaymentFormProps) {
  const fields = useMemo(() => {
    const inputFields: Array<IFormField> = [
      {
        name: 'icon',
        label: 'URL del logo',
        type: 'gallery',
        Component: InputGallery,
      },
      {
        name: 'name',
        label: 'Nombre del método de pago',
        type: 'text',
        Component: InputField,
      },
      {
        name: 'description',
        label: 'Descripción',
        type: 'text',
        multiline: true,
        Component: InputField,
      },
      [
        {
          name: 'public_key',
          label: 'Clave pública',
          type: 'text',
          Component: InputField,
        },
        {
          name: 'secret_key',
          label: 'Clave secreta',
          type: 'text',
          Component: InputField,
        },
      ],
    ];
    return inputFields;
  }, []);

  const defaultValues = useMemo(() => {
    const defValues = (props.paymethod ? new Paymethod(props.paymethod as Record<string, unknown>) : new Paymethod({})) as FormValuesProps;
    LOG.debug({ msg: 'defaultValues', defValues, paymethod: props.paymethod });
    return defValues;
  }, [props.paymethod]);

  const resolver = zodResolver(schemas);

  return { fields, defaultValues, resolver };
}
