import EntityPicker from '@/bundles/core/components/client/entity-picker';
import SimpleProductList from '@/bundles/products/components/client/simple-product-list';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

import useInputRelatedProduct, { InputRelatedProductProps } from './hooks/useUseInputRelatedProduct';

const InputRelatedProduct = (props: InputRelatedProductProps) => {
  const { items, filters, defaultFilterValues, entityName, url, count, onRemove, onSelectAll, onSelect, open, close, isOpen, columns, onChangePage } = useInputRelatedProduct(props);

  return (
    <Stack spacing={1}>
      <Stack spacing={1} flexDirection="row" alignItems="center">
        <Typography variant="h6">{props.label}</Typography>
        <Button onClick={open}>Agregar</Button>
      </Stack>
      <Stack spacing={1}>
        <SimpleProductList items={items} onRemove={onRemove} />
      </Stack>
      <EntityPicker
        count={count}
        onChangePage={onChangePage}
        selectedItems={new Set(items?.map((item) => item.id as string))}
        filters={filters}
        defaultFilterValues={defaultFilterValues}
        columns={columns as never}
        isOpen={isOpen}
        onClose={close}
        onSelectAll={onSelectAll}
        onSelect={onSelect}
        entityName={entityName}
        url={url}
      />
    </Stack>
  );
};

export default InputRelatedProduct;
