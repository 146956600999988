import { InputFieldProps } from '@/bundles/core/types';
import { ICollection } from '@/bundles/products/model/collection';
import { ILine } from '@/bundles/products/model/line';
import { IProduct } from '@/bundles/products/model/product';
import LOG from '@lib/logger';
import { arrayOfUniqueKey } from '@lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import useSWR from 'swr';

import useColumns from './useColumns';
import useItemFilters from './useItemFilters';

export type InputRelatedProductProps = {} & InputFieldProps;

export default function useInputRelatedProduct(props: InputRelatedProductProps) {
  const [items, setItems] = useState<IProduct[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState('');
  const [count, setCount] = useState(0);

  const collections = useSWR<ICollection[]>('/api/proxy/collections?limit=500', (url: string) =>
    fetch(url)
      .then((res) => res.json())
      ?.then((data) => arrayOfUniqueKey(data.data))
  );

  const lines = useSWR<ILine[]>('/api/proxy/lines?limit=500', (url: string) =>
    fetch(url)
      .then((res) => res.json())
      ?.then((data) => arrayOfUniqueKey(data.data))
  );

  const categories = useSWR<ILine[]>('/api/proxy/categories?limit=500', (url: string) =>
    fetch(url)
      .then((res) => res.json())
      ?.then((data) => arrayOfUniqueKey(data.data))
  );

  const { columns } = useColumns();
  const { filters } = useItemFilters({ collections: collections.data, lines: lines.data, categories: categories.data });

  const entityName = 'products';

  const url = useMemo(() => `/api/proxy/products?joins=[line,collection,category]&limit=${limit}&filter=${filter}&page=${page}`, [page, filter, limit]);

  const fieldArray = useFieldArray({ control: props.control, name: props.name });

  const defaultFilterValues = useMemo(() => ({
    status: '',
    'collection:name': '',
    'line:name': '',
    'category:name': ''
  }), []);

  const fetchRelatedProducts = async (selected: Set<string | number>) => {
    try {
      const ids = Array.from(selected);
      const promises = ids.map((id) => fetch(`/api/proxy/products/${id}?joins=[line,collection,category]`));
      const responses = await Promise.all(promises);
      const data = await Promise.all(responses.map((r) => r.json()));
      return data.map((d) => d.data) as IProduct[];
    } catch (error) {
      LOG.error({ msg: 'fetchRelatedProducts', error });
    }
    return [];
  };

  const updateFields = (products?: IProduct[]) => {
    if (!products) return;
    fieldArray.remove();
    products.forEach((product) => fieldArray.append({ productId: product.id, ...product }));
    setItems(products);
  };

  useEffect(() => {
    if (fieldArray.fields.length > 0) {
      const ids = fieldArray.fields.map((item: unknown) => (item as { productId: string }).productId);
      fetchRelatedProducts(new Set(ids))
        .then(setItems)
        .catch((err) => LOG.error({ msg: 'useEffect:updateFields', err }));
    }
  }, [fieldArray.fields]);

  const onRemove = (product: IProduct, items?: IProduct[]) => {
    LOG.debug({ msg: 'onRemove', product, items });
    const idx = items?.findIndex((item) => item.id === product.id);
    if (idx !== undefined && idx >= 0) fieldArray.remove(idx);
    LOG.debug({ msg: 'onRemove', idx, items, product, fieldArray: fieldArray });
  };

  const onSelectAll = () => {
    LOG.debug({ msg: 'onSelectAll', props });
  };

  const onSelect = async (items: Set<string | number>) => {
    LOG.debug({ msg: 'onSelect', items });
    const products = await fetchRelatedProducts(items);
    updateFields(products);
  };

  const onChangePage = (page: number) => {
    if (Number(page)) setPage(page);
  };

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  return { items, filters, defaultFilterValues, entityName, url, columns, isOpen, count, onRemove, open, close, onSelectAll, onSelect, onChangePage };
}
