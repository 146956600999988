import { NavItem } from '@lib/devias/lib/menu';

import { LayoutConfig } from '../core/menu';

const menu: NavItem = {
  key: 'customers',
  title: '',
  items: [
    {
      key: 'customers',
      title: 'Clientes',
      icon: 'users',
      items: [
        {
          key: 'customers:index',
          title: 'Consumidores',
          href: '/admin/customers',
        },
        {
          key: 'customers:create',
          title: 'Registrar',
          href: '/admin/customers/create',
        },
      ],
    },
  ],
};

export default {
  navItems: [new NavItem(menu)],
} satisfies LayoutConfig;
