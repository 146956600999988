'use client';

import { IProduct } from '@/bundles/products/model/product';
import { ColumnDef } from '@lib/devias/components/core/data-table';
import TableFormatter from '@lib/tables/table/formatters';
import ImageFormatter from '@lib/tables/table/formatters/image';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';

const useColumns = () => {
  const columns = useMemo(
    () =>
      [
        {
          formatter: (row: any) => {
            return (
              <ImageFormatter row={row} index="thumbnail">
                <Typography color="text.primary" sx={{ whiteSpace: 'nowrap' }} variant="subtitle2">
                  {row?.name}
                </Typography>
                <Box>
                  <span>Fecha: </span>
                  <span>{new Date(row?.createdAt).toLocaleString()}</span>
                </Box>
                <Box>
                  <span>SKU: </span>
                  <span>{row?.sku}</span>
                </Box>
              </ImageFormatter>
            );
          },
          name: 'Producto',
          width: '100%'
        },
        {
          formatter: (row) => <TableFormatter row={row?.line} index="name" />,
          name: 'Línea',
          width: '100px'
        },
        {
          formatter: (row) => <TableFormatter row={row?.category} index="name" />,
          name: 'Categoría',
          width: '100px'
        },
        {
          formatter: (row) => <TableFormatter row={row?.collection} index="name" />,
          name: 'Colección',
          width: '100px'
        }
      ] satisfies ColumnDef<IProduct>[],
    []
  );

  return { columns };
};

export default useColumns;
