import { IUserSession } from '@lib/auth/auth-devias/types/session';
import { usePopover } from '@lib/devias/hooks/use-popover';
import { AuthStrategy } from '@lib/devias/lib/auth/strategy';
import type { User } from '@lib/devias/types/user';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useSession } from 'next-auth/react';
import * as React from 'react';

import { Logout } from '@/bundles/core/components/client/logout';
import { config } from '@/config';

export interface UserPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open: boolean;
  user?: User & { email?: string; username?: string };
}

export function UserPopover({ anchorEl, onClose, open, user }: UserPopoverProps): React.JSX.Element {
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={onClose}
      open={Boolean(open)}
      slotProps={{ paper: { sx: { width: '280px' } } }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Box sx={{ p: 2 }}>
        <Typography>{user?.name}</Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.email || user?.username}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>{config.auth.strategy === AuthStrategy.CUSTOM ? <Logout /> : null}</Box>
    </Popover>
  );
}

const UserBtn = () => {
  const popover = usePopover<HTMLButtonElement>();
  const [user, setUser] = React.useState<IUserSession | null>(null);

  const { data: session } = useSession();

  React.useEffect(() => {
    if (session?.user) setUser(session?.user as IUserSession);
  }, [session]);

  return (
    <React.Fragment>
      <Box
        component="button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
          p: 0,
        }}
      >
        <Badge
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          color="success"
          sx={{
            '& .MuiBadge-dot': {
              border: '2px solid var(--MainNav-background)',
              borderRadius: '50%',
              bottom: '6px',
              height: '12px',
              right: '6px',
              width: '12px',
            },
          }}
          variant="dot"
        >
          <Avatar src={user?.image} />
        </Badge>
      </Box>
      <UserPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
    </React.Fragment>
  );
};

export default UserBtn;
