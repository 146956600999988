'use client';

import { FlashProvider } from '@lib/flash';
import { LoaderProvider } from '@lib/loaders';
import { fetcher } from '@lib/utils';
import { FC, ReactNode } from 'react';
import { SWRConfig } from 'swr';

export type CoreProviderProps = { children: ReactNode };

const CoreProvider: FC<CoreProviderProps> = ({ children }) => {
  return (
    <SWRConfig
      value={{
        revalidateIfStale: false,
        fetcher: fetcher,
      }}
    >
      <FlashProvider>
        <LoaderProvider>{children}</LoaderProvider>
      </FlashProvider>
    </SWRConfig>
  );
};

export default CoreProvider;
