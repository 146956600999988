import { IImage, Image } from '@/bundles/core';
import { BaseEntity, IBaseEntity } from '@/bundles/core/model';

export interface IPaymethod extends IBaseEntity {
  name?: string;
  description?: string;
  icon?: IImage;
  public_key?: string;
  secret_key?: string;
}

export class Paymethod extends BaseEntity implements IPaymethod {
  name?: string = '';
  description?: string = '';
  icon?: IImage = new Image({ url: '' });
  public_key?: string = '';
  secret_key?: string = '';

  constructor(data: Record<string, unknown> & IPaymethod) {
    super(data);
    this.name = data?.name || this.name;
    this.description = data?.description || this.description;
    this.icon = typeof data?.icon === 'string' ? new Image({ url: data?.icon || this.icon }) : new Image((data?.icon as Record<string, unknown>) || { url: '' });
    this.public_key = data?.public_key || this.public_key;
    this.secret_key = data?.secret_key || this.secret_key;
  }

  toFormData() {
    let data = Object.assign({}, this) as Record<string, unknown>;
    delete data.id;
    return data;
  }
}
