import { NavItemConfig } from '@/lib/devias/types/nav';

export interface ItemProps extends NavItemConfig {
  title: string;
  key: string;
  icon?: string;
  href?: string;
  items?: ItemProps[];
}

export interface NavItemProps {
  key: string;
  title: string;
  items: ItemProps[];
}

class Item implements ItemProps {
  title: string;
  key: string;
  icon?: string | undefined;
  href?: string | undefined;
  items?: ItemProps[] | undefined;
  disabled?: boolean | undefined;
  external?: boolean | undefined;
  label?: string | undefined;
  matcher?: { type: 'startsWith' | 'equals'; href: string } | undefined;

  constructor(arg: unknown & ItemProps) {
    Object.assign(this, arg);
    this.title = arg.title || '';
    this.key = arg.key || '';
    this.matcher = this.href ? { type: 'equals', href: this.href } : undefined;
    this.items = arg?.items?.map((i) => new Item(i));
  }
}

export class NavItem implements NavItemProps {
  key!: string;
  title!: string;
  items: Array<ItemProps>;

  constructor(arg: unknown & { items: unknown[] }) {
    Object.assign(this, arg);
    this.items = arg?.items?.map((i) => new Item(i as ItemProps)) as ItemProps[];
  }
}
