import { ColumnDef } from '@lib/devias/components/core/data-table';
import LOG from '@lib/logger';
import { IFilter } from '@lib/tables/table/filters';
import { debounce, objectToUrlSearchParams, objEmpty } from '@lib/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

export type EntityPickerProps = {
  filters?: unknown;
  defaultFilterValues?: Record<string, string>;
  applyFilters?: (filters: { [key: string]: string }) => void;
  columns: ColumnDef<never>;
  entityName: string;
  url: string;
  onSelectAll?: () => void;
  onSelect?: (items: Set<string | number>) => void;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  selectedItems?: Set<string | number>;
  onChangePage?: (page: number) => void;
  count: number;
};

export default function useEntityPicker(props: EntityPickerProps) {
  const [items, setItems] = useState<unknown[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

  const url = useMemo(() => {
    if (!objEmpty(filters)) {
      const searchFilter = objectToUrlSearchParams(filters);
      return `${props.url}&filters=[${searchFilter.replaceAll('=', ':').replaceAll('&', ',')}]`;
    }
    return props.url;
  }, [filters, props.url]);

  const resp = useSWR(url, {});

  useEffect(() => {
    if (!resp?.error && resp?.data && Array.isArray(resp?.data?.data)) {
      setItems(resp?.data?.data);
      setCount(resp?.data?.count);
    }
    LOG.debug({ msg: 'useEntityPicker:useEffect', request: resp.data, error: resp.error });
  }, [resp]);

  useEffect(() => {
    if (props.selectedItems) setSelectedItems(props.selectedItems as Set<string>);
  }, [props.selectedItems]);

  const onClose = () => {
    LOG.debug({ msg: 'onClose', selected: selectedItems, filters, items });
    props.onSelect?.(selectedItems);
    props.onClose?.();
  };

  const applyFilters = (filters: { [key: string]: string }) => {
    LOG.debug({ msg: 'applyFilters', filters });
    setFilters(filters);
  };

  const onSearchDebounced = useCallback(debounce((searchKey: string) => {
    applyFilters({ name: searchKey });
  }, 1000), []);

  const onSearch = (searchKey?: string, filter?: IFilter) => {
    if (searchKey !== undefined && !filter) onSearchDebounced(searchKey);
  };

  const onSelect = (ev: unknown, row: unknown) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      newSet.add((row as { id: string }).id);
      return newSet;
    });
  };

  const onDeselect = (ev: unknown, row: unknown) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      newSet.delete((row as { id: string }).id);
      return newSet;
    });
  };

  return { url, loading, items, selectedItems, count, onClose, applyFilters, onSearch, onSelect, onDeselect };
}
