import { NavItem } from '@lib/devias/lib/menu';

import { LayoutConfig } from '../core/menu';

const menu: NavItem = {
  key: 'stocks',
  title: '',
  items: [
    {
      key: 'productos',
      title: 'Productos',
      icon: 'shopping-bag-open',
      items: [
        {
          key: 'productos:index',
          title: 'Mis Productos',
          href: '/admin/products',
        },
        {
          key: 'productos:create',
          title: 'Nuevo Producto',
          href: '/admin/products/create',
        },
        {
          key: 'lines:index',
          title: 'Líneas',
          href: '/admin/products/lines',
        },
        {
          key: 'collections:index',
          title: 'Colecciones',
          href: '/admin/products/collections',
        },
        {
          key: 'sizes:index',
          title: 'Talles',
          href: '/admin/products/sizes',
        },
        {
          key: 'categories:index',
          title: 'Categorías',
          href: '/admin/products/categories',
        },
      ],
    },
  ],
};

export default {
  navItems: [new NavItem(menu)],
} satisfies LayoutConfig;
