'use client';

import MenuItem from '@mui/material/MenuItem';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import * as React from 'react';

import { toast } from '@/lib/devias/components/core/toaster';
import { useUser } from '@/lib/devias/hooks/use-user';
import { logger } from '@/lib/devias/lib/default-logger';

export function Logout(): React.JSX.Element {
  const { checkSession } = useUser();

  const router = useRouter();

  const handleSignOut = React.useCallback(async (): Promise<void> => {
    try {
      await signOut();

      // Refresh the auth state
      await checkSession?.();

      // UserProvider, for this case, will not refresh the router and we need to do it manually
      router.refresh();
      // After refresh, AuthGuard will handle the redirect
    } catch (err) {
      logger.error('Sign out error', err);
      toast.error('Something went wrong, unable to sign out');
    }
  }, [checkSession, router]);

  return (
    <MenuItem component="div" onClick={handleSignOut} sx={{ justifyContent: 'center' }}>
      Desconectar
    </MenuItem>
  );
}
