import { zodResolver } from '@hookform/resolvers/zod';
import LOG from '@lib/logger';
import { useMemo } from 'react';
import { z } from 'zod';

import InputField from '@/bundles/core/components/client/input-field';
import { IFormField } from '@/bundles/core/types';
import { Organization } from '@/bundles/general/model/organization';

import { OrganizationFormProps } from '../index';
import schemas from '../schema';

export type FormValuesProps = z.infer<typeof schemas>;

export type FormFieldsProps = {} & OrganizationFormProps;

const useFormFields = (props: FormFieldsProps) => {
  const fields = useMemo(() => {
    const inputFields: Array<IFormField> = [
      {
        name: 'name',
        label: 'Nombre de la organización',
        type: 'text',
        Component: InputField,
      },
      {
        name: 'cuit',
        label: 'Nro de CUIT',
        type: 'text',
        Component: InputField,
      },
      [
        {
          name: 'dataFiscal.url',
          label: 'URL de la DataFiscal',
          type: 'text',
          Component: InputField,
        },
        {
          name: 'dataFiscal.code',
          label: 'Código de la DataFiscal',
          type: 'text',
          Component: InputField,
        },
        {
          name: 'dataFiscal.qr',
          label: 'QR de la DataFiscal',
          type: 'text',
          Component: InputField,
        },
      ],
    ];
    return inputFields;
  }, []);

  const defaultValues = useMemo(() => {
    const defValues = (props.organization ? new Organization(props.organization as Record<string, unknown>) : new Organization({})) as FormValuesProps;
    LOG.debug({ msg: 'defaultValues', defValues, organization: props.organization });
    return defValues;
  }, [props.organization]);

  const resolver = zodResolver(schemas);

  return { fields, defaultValues, resolver };
};

export default useFormFields;
