import Box from '@mui/material/Box';
import { FC } from 'react';

import ItemList from '@/bundles/core/components/client/item-list';

import useColumns, { SimpleProductListProps } from './hooks/useColumns';

const SimpleProductList: FC<SimpleProductListProps> = (props) => {
  const { columns } = useColumns(props);
  return (
    <Box>
      <ItemList items={props.items || []} columns={columns} />
    </Box>
  );
};
export default SimpleProductList;
