'use client';

import { ColumnDef } from '@lib/devias/components/core/data-table';
import LOG from '@lib/logger';
import TableFormatter from '@lib/tables/table/formatters';
import ActionFormatter from '@lib/tables/table/formatters/action';
import ImageFormatter from '@lib/tables/table/formatters/image';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';

import { IProduct } from '@/bundles/products/model/product';

export type SimpleProductListProps = { items?: IProduct[]; onRemove: (arg: IProduct, items?: IProduct[]) => void; onRefresh?: () => void };

const useColumns = (props?: SimpleProductListProps) => {
  const columns = useMemo(
    () =>
      [
        {
          formatter: (row: any) => {
            return (
              <ImageFormatter row={row} index="thumbnail">
                <Typography variant="subtitle2">{row.name}</Typography>
                <Box>
                  <span>Fecha: </span>
                  <span>{new Date(row.createdAt).toLocaleString()}</span>
                </Box>
                <Box>
                  <span>SKU: </span>
                  <span>{row.sku}</span>
                </Box>
              </ImageFormatter>
            );
          },
          name: 'Producto',
          width: '100%',
        },
        {
          formatter: (row) => <TableFormatter row={row.line} index="name" />,
          name: 'Línea',
          width: '100px',
        },
        {
          formatter: (row) => <TableFormatter row={row.collection} index="name" />,
          name: 'Colección',
          width: '100px',
        },
        {
          formatter: (arg) => (
            <ActionFormatter
              onClick={() => {
                LOG.debug({ msg: 'useColumns::onRemove', arg, items: props?.items });
                props?.onRemove(arg, props.items);
              }}
              Icon={DeleteOutlineIcon}
            />
          ),
          name: 'Delete',
          hideName: true,
          width: '50px',
          align: 'right',
        },
      ] satisfies ColumnDef<IProduct>[],
    [props?.items],
  );

  return { columns };
};

export default useColumns;
