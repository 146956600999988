import { TextEditor } from '@lib/devias/components/core/text-editor/text-editor';
import { getValueFromPath } from '@lib/utils';
import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { EditorEvents } from '@tiptap/react';
import React from 'react';
import { Controller } from 'react-hook-form';

import InputFieldTip from '@/bundles/core/components/client/input-tip';
import { InputFieldProps } from '@/bundles/core/types';

export type InputTextEditorProps = {} & InputFieldProps;

const InputTextEditor = (props: InputTextEditorProps) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      disabled={props.disabled}
      rules={props.rules}
      render={({ field }) => {
        const errors = getValueFromPath(field.name, props.errors as never) as { message: string };
        const hasError = Boolean(errors?.message);
        const error = hasError ? (errors?.message as string) : null;
        // LOG.debug({ msg: 'InputField', field, hasError, error, rules: props.rules });
        return (
          <FormControl error={hasError} fullWidth>
            <InputLabel sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              {props.label || field.name}
              {props.tips?.map((tip, idx: number) => <InputFieldTip key={idx} title={tip.title} Icon={tip.Icon} />)}
            </InputLabel>
            <Box sx={{ mt: '8px', '& .tiptap-container': { height: '400px' } }}>
              <TextEditor
                placeholder={props.placeholder || 'Escribe algo'}
                content={field.value ?? ''}
                onUpdate={({ editor }: EditorEvents['update']) => {
                  field.onChange(editor.getText());
                }}
              />
            </Box>
            {hasError ? <FormHelperText>{error}</FormHelperText> : <FormHelperText></FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default InputTextEditor;
