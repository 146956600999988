import { NavItem } from '@lib/devias/lib/menu';

import { LayoutConfig } from '../core/menu';

const menu: NavItem = {
  key: 'dashboard',
  title: 'Dashboard',
  items: [
    {
      key: 'analytics',
      title: 'Analíticas',
      href: '/admin/dashboard/analytics',
      icon: 'chart-pie',
    },
    {
      key: 'orders',
      title: 'Pedidos',
      href: '/admin/dashboard/sales',
      icon: 'shopping-cart-simple',
    },
  ],
};

export default { navItems: [new NavItem(menu)] } satisfies LayoutConfig;
