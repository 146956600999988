import ItemList from '@/bundles/core/components/client/item-list';
import AppModal from '@/bundles/core/components/client/modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC } from 'react';

import useEntityPicker, { EntityPickerProps } from './hooks/useEntityPicker';

const EntityPicker: FC<EntityPickerProps> = (props) => {
  const { onClose, count, items, applyFilters, onSearch, onSelect, onDeselect, selectedItems } = useEntityPicker(props);

  return (
    <AppModal onClose={onClose} open={props.isOpen}>
      <Stack flexDirection="column" height={'100%'} width={'80vw'} overflow={'hidden'}>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box px={2}>{props.title}</Box>
          <Box>
            <Button type="button" variant="text" onClick={onClose}>
              <CloseIcon />
            </Button>
          </Box>
        </Stack>
        <Stack sx={{ flex: 1, overflow: 'auto' }}>
          <Box>
            <ItemList
              selectable
              count={count}
              searchOptions={{ title: 'Buscar', onChange: onSearch }}
              defaultFilterValues={props.defaultFilterValues}
              applyFilters={applyFilters}
              filters={props.filters}
              items={items}
              columns={props.columns}
              selectedItems={selectedItems}
              onSelect={onSelect}
              onDeselect={onDeselect}
              onChangePage={props.onChangePage}
            />
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box px={2}></Box>
          <Box p={2}>
            <Button type="button" variant="contained" onClick={onClose}>
              Aplicar
            </Button>
          </Box>
        </Stack>
      </Stack>
    </AppModal>
  );
};

export default EntityPicker;
