import { useFlash } from '@lib/flash';
import LOG from '@lib/logger';
import { objEmpty } from '@lib/utils';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import EntityManager from '@/bundles/core/services/entity-manager';
import { IOrganization } from '@/bundles/general/model/organization';
import { IShop, Shop } from '@/bundles/general/model/shop';

import { ShopFormProps } from '../index';
import useFormFields, { FormValuesProps } from './useFormFields';

export type useShopFormProps = {} & ShopFormProps;

export default function useShopForm(props: useShopFormProps) {
  const flash = useFlash();
  const { fields, defaultValues, resolver } = useFormFields(props);
  const { control, handleSubmit, ...form } = useForm<FormValuesProps>({ defaultValues, resolver });

  const onSubmit = async (values: FormValuesProps) => {
    try {
      LOG.debug({ msg: 'onSubmit', values });
      const shopManager = new EntityManager<IShop>('/', '/api/proxy/shops');
      const organizationManager = new EntityManager<IOrganization>('/', '/api/proxy/organizations');
      const organization = ((await organizationManager.getAll({ limit: '1' }))?.data as IOrganization[])?.at(0);

      const newShop = new Shop({
        ...values,
        organizationId: organization?.id,
        id: props?.shop?.id
      }).toFormData();

      const { error } = await shopManager.save(newShop, ['favicon', 'banners']);
      if (error) throw new Error(error);
      flash.toast('Datos guardados con éxitos!', 'info');
    } catch (error) {
      LOG.warn({ msg: 'onSubmit', error });
      flash.toast('No pudimos guardar los datos', 'error', (error as Error).message);
    }
  };

  useEffect(() => {
    if (!objEmpty(form.formState.errors)) {
      const error = JSON.stringify(form.formState.errors, null, 2);
      flash.toast('Hay errores en el formulario', 'error', error);
      LOG.warn({ msg: 'useCustomerForm::formState.errors', errors: form.formState.errors });
    }
  }, [form.formState.errors]);

  return { onSubmit, handleSubmit, control, form, fields };
}
