import type { ColumnDef } from '@lib/devias/components/core/data-table';
import { Box, IconButton } from '@mui/material';
import { Trash as TrashIcon } from '@phosphor-icons/react/dist/ssr/Trash';
import React, { useMemo } from 'react';

import { IImage } from '@/bundles/core';

const useImageColumns = ({ onRemove }: { onRemove?: (image: IImage) => void }) => {
  const columns = useMemo(() => {
    return [
      {
        formatter: (row): React.JSX.Element => {
          return (
            <Box
              sx={{
                backgroundImage: `url(${row?.url})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                bgcolor: 'var(--mui-palette-background-level2)',
                borderRadius: 1,
                flex: '0 0 auto',
                height: '40px',
                width: '40px',
              }}
            />
          );
        },
        name: 'Imagen',
        width: '100px',
      },
      { field: 'fileName', name: 'Nombre del archivo', width: '300px' },
      {
        formatter: (row): React.JSX.Element => (
          <IconButton onClick={() => onRemove?.(row)}>
            <TrashIcon />
          </IconButton>
        ),
        name: 'Actions',
        hideName: true,
        width: '100px',
        align: 'right',
      },
    ];
  }, [onRemove]) satisfies ColumnDef<IImage>[];
  return { columns };
};

export default useImageColumns;
