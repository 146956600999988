import Dialog from '@mui/material/Dialog';
import { FC, ReactNode } from 'react';

export type AppModalProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};

const AppModal: FC<AppModalProps> = (props) => {
  const { children } = props;

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false}>
      {children}
    </Dialog>
  );
};

export default AppModal;
