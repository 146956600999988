import { zodResolver } from '@hookform/resolvers/zod';
import LOG from '@lib/logger';
import { buildFormSelectOptions } from '@lib/utils';
import { Info as InfoIcon } from '@phosphor-icons/react/dist/ssr/Info';
import { useMemo } from 'react';
import { z } from 'zod';

import InputArrayField from '@/bundles/core/components/client/input-array-field';
import InputCheckboxField from '@/bundles/core/components/client/input-checkbox-field';
import InputEntitySelector from '@/bundles/core/components/client/input-entity-selector';
import InputField from '@/bundles/core/components/client/input-field';
import InputGallery from '@/bundles/core/components/client/input-gallery';
import InputSelect from '@/bundles/core/components/client/input-select';
import { IFormField } from '@/bundles/core/types';

import { Product } from '../../../../model/product';
import InputRelatedProduct from '../components/input-related-product';
import schemas from '../schema';
import { ProductFormType } from './useProductForm';

export type FormValuesProps = z.infer<typeof schemas>;

const useFormFields = (props: ProductFormType) => {
  const fields = useMemo(() => {
    const inputFields: Array<IFormField> = [
      {
        name: 'thumbnail',
        label: 'Imagen de portada',
        type: 'image',
        Component: InputGallery,
      },
      {
        name: 'gallery',
        label: 'Imágenes del producto',
        type: 'image',
        multiple: true,
        Component: InputGallery,
      },
      [
        {
          name: 'name',
          label: 'Nombre del producto',
          type: 'text',
          required: true,
          Component: InputField,
        },
        {
          name: 'status',
          label: 'Estado de publicación',
          type: 'select',
          options: [
            { label: 'Publicado', value: 'publicado' },
            { label: 'En revisión', value: 'pendiente' },
            { label: 'Pausado', value: 'pausado' },
          ],
          Component: InputSelect,
        },
      ],
      [
        {
          name: 'collection.id',
          label: 'Colección',
          type: 'select',
          entityField: {
            name: 'collection.name',
            label: 'Nombre de la colección',
            type: 'text',
            Component: InputField,
          },
          Component: InputEntitySelector,
          options: buildFormSelectOptions(props.collections),
        },
        {
          name: 'line.id',
          label: 'Línea',
          type: 'select',
          entityField: {
            name: 'line.name',
            label: 'Nombre de la línea',
            type: 'text',
            Component: InputField,
          },
          Component: InputEntitySelector,
          options: buildFormSelectOptions(props.lines),
        },
        {
          name: 'category.id',
          label: 'Categoría',
          type: 'select',
          entityField: {
            name: 'category.name',
            label: 'Nombre de la categoría',
            type: 'text',
            Component: InputField,
          },
          Component: InputEntitySelector,
          options: buildFormSelectOptions(props.categories),
        },
      ],
      {
        name: 'description',
        label: 'Descripción',
        type: 'text',
        multiline: true,
        Component: InputField,
      },
      [
        {
          name: 'price.public.value',
          label: 'Precio de venta',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
        {
          name: 'price.franchise.factor',
          label: '% franquicia',
          type: 'number',
          rules: { valueAsNumber: true },
          tips: [
            {
              Icon: InfoIcon as never,
              title: 'Porcentaje en base al precio público',
            },
          ],
          Component: InputField,
        },
        {
          name: 'price.franchise.value',
          label: 'Precio de franquicia',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
        {
          name: 'price.mayor.factor',
          label: '% mayorista',
          type: 'number',
          rules: { valueAsNumber: true },
          tips: [
            {
              Icon: InfoIcon as never,
              title: 'Porcentaje en base al precio público',
            },
          ],
          Component: InputField,
        },
        {
          name: 'price.mayor.value',
          label: 'Precio de mayorista',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
      ],
      [
        {
          name: 'quantity.stock.value',
          label: 'En stock',
          type: 'number',
          required: true,
          rules: { valueAsNumber: true },
          Component: InputField,
        },
        {
          name: 'quantity.available.value',
          label: 'Stock disponible',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
        {
          name: 'quantity.minimum.value',
          label: 'Stock mínimo',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
      ],
      [
        {
          name: 'sku',
          label: 'Stock Keeping Unit (SKU)',
          type: 'text',
          required: true,
          Component: InputField,
        },
        {
          name: 'barcode',
          label: 'Código de barra',
          type: 'text',
          Component: InputField,
        },
      ],
      [
        {
          name: 'width',
          label: 'Largo',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
        {
          name: 'height',
          label: 'Alto',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
        {
          name: 'length',
          label: 'Ancho',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
        {
          name: 'weight',
          label: 'Peso',
          type: 'number',
          rules: { valueAsNumber: true },
          Component: InputField,
        },
      ],
      [
        {
          name: 'backorder',
          label: 'Vender sin stock',
          type: 'checkbox',
          Component: InputCheckboxField,
          tips: [
            {
              Icon: InfoIcon as never,
              title: 'Permite seguir vendiendo un producto aunque no haya stock disponible',
            },
          ],
        },
      ],
      {
        name: 'colors',
        label: 'Colores',
        type: 'array',
        defaultArrayItemValue: { id: '', name: '' },
        Component: InputArrayField,
        entityField: {
          name: 'id',
          label: 'Color',
          type: 'select',
          Component: InputEntitySelector,
          entityField: {
            name: 'name',
            label: 'Nombre del color',
            type: 'text',
            Component: InputField,
          },
          options: buildFormSelectOptions(props.colors),
        },
      },
      {
        name: 'sizes',
        label: 'Talles',
        type: 'array',
        defaultArrayItemValue: { id: '', name: '' },
        Component: InputArrayField,
        entityField: {
          name: 'id',
          label: 'Talle',
          type: 'select',
          Component: InputEntitySelector,
          entityField: {
            name: 'name',
            label: 'Nombre del talle',
            type: 'text',
            Component: InputField,
          },
          options: buildFormSelectOptions(props.sizes),
        },
      },
      {
        name: 'related',
        label: 'Productos relacionados',
        type: 'related-products',
        Component: InputRelatedProduct,
      },
    ];
    return inputFields;
  }, []);

  const defaultValues = useMemo(() => {
    const defValues = (props.product ? new Product(props.product as Record<string, unknown>) : new Product({})) as FormValuesProps;
    LOG.debug({ msg: 'defaultValues', defValues, product: props.product });
    return defValues;
  }, [props.product]);

  const resolver = zodResolver(schemas);

  return { fields, defaultValues, resolver };
};

export default useFormFields;
