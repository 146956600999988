import { arrayOfUniqueKey } from '@lib/utils';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, Suspense } from 'react';

import Breadcrumbs from '@/bundles/core/components/client/breadcrumbs';
import { AppPageProps } from '@/bundles/core/types';
import { ICategory } from '@/bundles/products/model/category';
import { ICollection } from '@/bundles/products/model/collection';
import { IColor } from '@/bundles/products/model/color';
import { ILine } from '@/bundles/products/model/line';
import { IProduct } from '@/bundles/products/model/product';
import { ISize } from '@/bundles/products/model/size';
import ProductManager from '@/bundles/products/services/product-manager';

import EntityManager from '../../../core/services/entity-manager';
import ProductForm from '../../components/client/product-form';
import paths from '../../paths';

const API_PROXY_URL = process.env.API_PROXY_URL || '/';

const productManager = new ProductManager(API_PROXY_URL, '/api/products');
const collectionManager = new EntityManager<ICollection>(API_PROXY_URL, '/api/collections');
const lineManager = new EntityManager<ILine>(API_PROXY_URL, '/api/lines');
const categoryManager = new EntityManager<ICategory>(API_PROXY_URL, '/api/categories');
const sizeManager = new EntityManager<ISize>(API_PROXY_URL, '/api/sizes');
const colorManager = new EntityManager<IColor>(API_PROXY_URL, '/api/colors');

const CreatePage: FC<AppPageProps> = async (props) => {
  const product: IProduct | null = ((
      await productManager.getById(props?.params?.id, {
        joins: '[collection,line,sizes:size,colors:color,categories:category,category]',
      }))?.data as IProduct) || null;

  const collections = ((await collectionManager.getAll({limit:"500"}))?.data as ICollection[]) || [];
  const lines = ((await lineManager.getAll({limit:"500"}))?.data as ILine[]) || [];
  const categories = ((await categoryManager.getAll({limit:"500"}))?.data as ICategory[]) || [];
  const sizes = ((await sizeManager.getAll({limit:"500"}))?.data as ISize[]) || [];
  const colors = ((await colorManager.getAll({limit:"500"}))?.data as IColor[]) || [];

  return (
    <Suspense>
      <Box sx={{ p: 5 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Breadcrumbs paths={[{ href: paths.index, name: 'Products' }]} />
            <Stack spacing={1}>
              <Typography variant="h4">{product?.name ? product?.name : 'Nuevo producto'}</Typography>
              <Typography variant="body1">{product?.sku ? `SKU: ${product?.sku}` : ''}</Typography>
              <Typography variant="body2">{product?.createdAt ? `Ultima actualización: ${new Date(product?.createdAt).toLocaleString()}` : ''}</Typography>
            </Stack>
          </Stack>
          <ProductForm
            product={product}
            lines={arrayOfUniqueKey(lines)}
            collections={arrayOfUniqueKey(collections)}
            categories={arrayOfUniqueKey(categories)}
            colors={arrayOfUniqueKey(colors)}
            sizes={arrayOfUniqueKey(sizes)}
          />
        </Stack>
      </Box>
    </Suspense>
  );
};

export default CreatePage;
