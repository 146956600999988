import { IImage, Image } from '@/bundles/core';
import { IBaseEntity } from '@/bundles/core/model';
import BaseEntity from '@/bundles/core/model/base-entity';

export interface IPost extends IBaseEntity {
  status?: string;
  postType?: string;
  title?: string;
  thumbnail?: IImage;
  slug?: string;
  content?: string;
}

export class Post extends BaseEntity implements IPost {
  status?: string = 'pendiente';
  postType?: string = 'faq';
  title?: string = '';
  thumbnail?: IImage = '';
  slug?: string = '';
  content?: string = '';

  constructor(data: Record<string, any>) {
    super(data);
    this.status = data.status || this.status;
    this.postType = data.postType || this.postType;
    this.title = data.title || this.title;
    this.thumbnail = data.thumbnail || this.thumbnail;
    this.thumbnail = typeof data.thumbnail === 'string' ? new Image({ url: data.thumbnail }) : new Image(data.thumbnail);
    this.slug = data.slug || this.slug;
    this.content = data.content || this.content;
  }

  toFormData() {
    let data = super.toFormData() as Record<string, unknown>;
    data.thumbnail = this.thumbnail?.url || '';
    return data;
  }
}
