import { IImage, Image } from '@/bundles/core';
import { BaseEntity, IBaseEntity } from '@/bundles/core/model';

export interface IShop extends IBaseEntity {
  title?: string;
  description?: string;
  keywords?: string;
  favicon?: IImage | string;
  theme?: string;
  banners?: IImage[] | string[];
  organizationId?: string;
  dangerousScriptHeader?: string;
  dangerousScriptFooter?: string;
  googleClientId?: string;
  googleClientSecret?: string;
  sendgridApiKey?: string;
  sendgridFrom?: string;
  facebookPixelId?: string;
}

export class Shop extends BaseEntity implements IShop {
  title?: string = '';
  description?: string = '';
  keywords?: string = '';
  favicon?: IImage = new Image({ url: '' });
  theme?: string = '';
  banners?: IImage[] | string[] = [];
  organizationId?: string = '';
  dangerousScriptHeader?: string = '';
  dangerousScriptFooter?: string = '';
  googleClientId?: string = '';
  googleClientSecret?: string = '';
  sendgridApiKey?: string = '';
  sendgridFrom?: string = '';
  facebookPixelId?: string = '';


  constructor(data: Record<string, unknown> & IShop) {
    super(data);
    this.title = data?.title || this.title;
    this.description = data?.description || this.description;
    this.keywords = data?.keywords || this.keywords;
    this.favicon = typeof data?.favicon === 'string' ?
      new Image({ url: (data?.favicon || this.favicon) as string }) :
      new Image((data?.favicon as Record<string, unknown>) || { url: '' });

    this.theme = data?.theme || this.theme;
    this.banners = Array.isArray(data?.banners) ?
      data?.banners.map((item) => {
        if(typeof item === 'string') return new Image({ url: item });
        return new Image((item as Record<string, unknown>) || { url: '' })
      }) :
      this.banners;
    this.organizationId = data?.organizationId || this.organizationId;
    this.dangerousScriptHeader = data?.dangerousScriptHeader || this.dangerousScriptHeader;
    this.dangerousScriptFooter = data?.dangerousScriptFooter || this.dangerousScriptFooter;
    this.googleClientId = data?.googleClientId || this.googleClientId;
    this.googleClientSecret = data?.googleClientSecret || this.googleClientSecret;
    this.sendgridApiKey = data?.sendgridApiKey || this.sendgridApiKey;
    this.sendgridFrom = data?.sendgridFrom || this.sendgridFrom;
    this.facebookPixelId = data?.facebookPixelId || this.facebookPixelId;

  }
}
