import { NavItem } from '@lib/devias/lib/menu';

import { LayoutConfig } from '../core/menu';

const menu: NavItem = {
  key: 'blog',
  title: '',
  items: [
    {
      key: 'blog',
      title: 'Blog',
      icon: 'text-align-left',
      items: [
        {
          key: 'blog:index',
          title: 'Artículos',
          href: '/admin/blog',
        },
        {
          key: 'blog:create',
          title: 'Nuevo Artículo',
          href: '/admin/blog/create',
        },
      ],
    },
  ],
};

export default {
  navItems: [new NavItem(menu)],
} satisfies LayoutConfig;
