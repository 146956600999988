import { ICategory } from '@/bundles/products/model/category';

import { ICollection } from '@/bundles/products/model/collection';
import { ILine } from '@/bundles/products/model/line';
import LOG from '@lib/logger';
import { useEffect, useMemo } from 'react';

export type useItemFiltersProps = { collections?: ICollection[]; lines?: ILine[], categories?: ICategory[] };

const useItemFilters = ({ collections, lines, categories }: useItemFiltersProps) => {
  useEffect(() => {
    LOG.debug({ msg: 'useItemFilters:useEffect', collections, lines, categories });
  }, [collections, lines, categories]);

  const filters = useMemo(
    () => [
      {
        type: 'dropdown',
        filterKey: 'status',
        name: 'Estado',
        filters: [
          { label: 'Pendiente', value: 'pendiente' },
          { label: 'Pausado', value: 'pausado' },
          { label: 'Publicado', value: 'publicado' }
        ]
      },
      {
        type: 'dropdown',
        name: 'Colección',
        filterKey: 'collection:name',
        filters: Array.isArray(collections) ? collections?.map((item) => item?.name && { label: item.name, value: item.name }) : []
      },
      {
        type: 'dropdown',
        filterKey: 'line:name',
        name: 'Línea',
        filters: Array.isArray(lines) ? lines?.map((item) => item?.name && { label: item.name, value: item.name }) : []
      },
      {
        type: 'dropdown',
        filterKey: 'category:name',
        name: 'Categoría',
        filters: Array.isArray(categories) ? categories?.map((item) => item?.name && { label: item.name, value: item.name }) : []
      }
    ],
    [collections, lines, categories]
  );
  return { filters };
};

export default useItemFilters;
