import Option from '@lib/devias/components/core/option';
import { Button, FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';

import RowField from '@/bundles/core/components/client/row-field';
import { InputFieldProps } from '@/bundles/core/types';

export type InputEntitySelectorProps = {} & InputFieldProps;

const InputEntitySelector: React.FC<InputEntitySelectorProps> = (props) => {
  const { control, errors, options, name, entityField, placeholder, label } = props;
  const [isVisible, setVisible] = React.useState(false);

  const toggle = () => setVisible(!isVisible);

  return (
    <Stack spacing={1}>
      <Box>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <InputLabel>{label}</InputLabel>
          <Button size="small" onClick={() => toggle()}>
            {!isVisible ? 'Crear' : 'Selecionar'}
          </Button>
        </Stack>
        <Controller
          disabled={isVisible}
          control={control}
          name={name}
          render={({ field }) => {
            const hasError = Boolean(props.errors?.[field.name]);
            const error = (props.errors?.[field.name] as Record<string, string>)?.message as string;
            return (
              <FormControl error={hasError} fullWidth>
                <Select {...field}>
                  <Option value="">Seleccionar</Option>
                  {options?.map((option, key) => (
                    <Option key={key} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {hasError ? <FormHelperText error>{error}</FormHelperText> : null}
              </FormControl>
            );
          }}
        />
      </Box>
      {isVisible && entityField && (
        <Box>
          <RowField field={entityField} arrayIndex={props.arrayIndex} control={control as never} form={props.form} />
        </Box>
      )}
    </Stack>
  );
};

export default InputEntitySelector;
