import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { Suspense } from 'react';

import Breadcrumbs from '@/bundles/core/components/client/breadcrumbs';
import EntityManager from '@/bundles/core/services/entity-manager';
import ShopForm from '@/bundles/general/components/client/shop-form';
import { IShop } from '@/bundles/general/model/shop';
import paths from '@/bundles/general/paths';

const API_PROXY_URL = process.env.API_PROXY_URL || '/';

const shopManager = new EntityManager<IShop>(API_PROXY_URL, '/api/shops');

const ShopPage = async () => {
  const shop = ((await shopManager.getAll({ limit: '1' }))?.data as IShop[])?.at(0);

  return (
    <Suspense>
      <Box sx={{ p: 5 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Breadcrumbs
              paths={[
                { href: paths.index, name: 'General' },
                { href: paths.organization, name: 'Tienda' },
              ]}
            />
            <Stack spacing={1}>
              <Typography variant="h4">{shop?.title ? shop?.title : 'Datos de la tienda'}</Typography>
              <Typography variant="body2">{shop?.createdAt ? `Ultima actualización: ${new Date(shop?.createdAt).toLocaleString()}` : ''}</Typography>
            </Stack>
          </Stack>
          <ShopForm shop={shop} />
        </Stack>
      </Box>
    </Suspense>
  );
};

export default ShopPage;
