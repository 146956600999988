import AuthProvider from '@lib/auth/auth-devias/provider';
import { getServerAuthSession } from '@lib/auth/auth-devias/services/auth/session';
import DeviasProvider from '@lib/devias/provider';
import { FC, ReactNode, Suspense } from 'react';

import CircleSpinner from '../../../../../lib/loaders/components/circle';

const CoreServerProvider: FC<{ children: ReactNode; settings?: any }> = async ({ children, settings }) => {
  const session = await getServerAuthSession();
  return (
    <Suspense fallback={<CircleSpinner />}>
      <AuthProvider session={session}>
        <DeviasProvider settings={settings}>{children}</DeviasProvider>
      </AuthProvider>
    </Suspense>
  );
};

export default CoreServerProvider;
