import { Checkbox, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import InputFieldTip from '@/bundles/core/components/client/input-tip';
import { InputFieldProps } from '@/bundles/core/types';

export type InputCheckboxFieldProps = {} & InputFieldProps;

const InputCheckboxField: FC<InputCheckboxFieldProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        const hasError = Boolean(props.errors?.[field.name]);
        const error = (props.errors?.[field.name] as Record<string, string>)?.message as string;
        return (
          <FormControlLabel
            control={<Checkbox {...field} />}
            label={
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="body2">{props.label || field.name}</Typography>
                {hasError ? <FormHelperText>{error}</FormHelperText> : <FormHelperText></FormHelperText>}
                {props.tips?.map((tip, idx: number) => <InputFieldTip key={idx} title={tip.title} Icon={tip.Icon} />)}
              </Stack>
            }
          />
        );
      }}
    />
  );
};

export default InputCheckboxField;
