import { AuthOptions, User } from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';
import GoogleProvider from 'next-auth/providers/google';

import { IGoogleAccount } from '../types/google';

const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID || '';
const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET || '';
const ALLOWED_EMAILS = process.env.ALLOWED_EMAILS || '';
const API_PROXY_URL = process.env.API_PROXY_URL || '';

export const authOptions: AuthOptions = {
  session: {
    strategy: 'jwt',
    maxAge: 1 * 24 * 60 * 60,
    updateAge: 24 * 60 * 60,
  },
  providers: [
    CredentialsProvider({
      name: 'Credentials',
      credentials: {
        username: { label: 'Usuario', type: 'text', placeholder: 'miusuario@email.com' },
        password: { label: 'Clave', type: 'password' },
      },
      async authorize(credentials, req) {
        // You need to provide your own logic here that takes the credentials
        // submitted and returns either a object representing a user or value
        // that is false/null if the credentials are invalid.
        // e.g. return { id: 1, name: 'J Smith', email: 'jsmith@example.com' }
        // You can also use the `req` object to obtain additional parameters
        // (i.e., the request IP address)

        const url = `${API_PROXY_URL}/api/users?filters=[email:${credentials?.username}]`;

        const res = await fetch(url, { method: 'GET' });
        const user = (await res.json())?.data[0];

        if (res.ok && user) {
          return user;
        }

        return null;
      },
    }),
    GoogleProvider({
      clientId: GOOGLE_CLIENT_ID,
      clientSecret: GOOGLE_CLIENT_SECRET,
      authorization: {
        params: {
          prompt: 'consent',
          access_type: 'offline',
          response_type: 'code',
        },
      },
    }),
  ],
  callbacks: {
    async signIn({ account, profile, user, credentials, ...opts }) {
      let isAutorized = false;
      const email = profile?.email || user?.email || '';
      const password = (user as User & { password?: string })?.password || '';

      if (account?.provider === 'google') {
        const email_verified = (profile as IGoogleAccount)?.email_verified;
        const defaultAllowedEmails = ALLOWED_EMAILS?.split(',') || [];
        isAutorized = email_verified && defaultAllowedEmails.includes(email);
      }

      if (account?.provider === 'credentials') {
        const hash = credentials?.password || '';
        // isAutorized = bcrypt.compareSync(password, hash as string);
        isAutorized = email === credentials?.username;
      }

      return isAutorized;
    },

    async jwt({ token, account }) {
      // Persist the OAuth access_token to the token right after signin
      if (account) {
        token.accessToken = account.access_token;
      }
      return token;
    },
    async session({ session, token, user }) {
      // Send properties to the client, like an access_token from a provider.
      // @ts-expect-error
      session.accessToken = token.accessToken;
      return session;
    },
    async redirect({ url, baseUrl }) {
      let redir = url.startsWith('/') ? `${baseUrl}${url}` : new URL(url).searchParams.get('callbackUrl') || url;
      return redir;
    },
  },
  pages: {
    signIn: '/auth/sign-in',
    signOut: '/auth/sign-in',
    error: '/auth/sign-in', // Error code passed in query string as ?error=
    newUser: '/auth/sign-up',
    verifyRequest: '/auth/sign-in', // (used for check email message)
  },
};

export default authOptions;
