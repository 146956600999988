import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, Suspense } from 'react';

import PostForm from '@/bundles/blog/components/client/post-form';
import { IPost } from '@/bundles/blog/model/post';
import paths from '@/bundles/blog/paths';
import Breadcrumbs from '@/bundles/core/components/client/breadcrumbs';
import EntityManager from '@/bundles/core/services/entity-manager';
import { AppPageProps } from '@/bundles/core/types';

const API_PROXY_URL = process.env.API_PROXY_URL || '/';

const postManager = new EntityManager<IPost>(API_PROXY_URL, '/api/posts');

const CreatePage: FC<AppPageProps> = async (props) => {
  const post = (await postManager.getById(props?.params?.id, {}))?.data as IPost;
  return (
    <Suspense>
      <Box sx={{ p: 5 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Breadcrumbs paths={[{ href: paths.index, name: 'Artículos' }]} />
            <Stack spacing={1}>
              <Typography variant="h4">{post?.title ? post?.title : 'Nuevo artículo'}</Typography>
              <Typography variant="body2">{post?.createdAt ? `Ultima actualización: ${new Date(post?.createdAt).toLocaleString()}` : ''}</Typography>
            </Stack>
          </Stack>
          <PostForm post={post} />
        </Stack>
      </Box>
    </Suspense>
  );
};

export default CreatePage;
