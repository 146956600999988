import { z } from 'zod';

const schemas = z.object({
  name: z.string().max(255),
  sku: z.string().max(255).optional(),
  barcode: z.string().max(255).optional(),
  status: z.string().max(255).optional(),
  description: z.string().optional(),
  collection: z
    .object({
      id: z.string().max(255),
      name: z.string().max(255).optional(),
    })
    .partial()
    .optional(),
  line: z
    .object({
      id: z.string().max(255),
      name: z.string().max(255).optional(),
    })
    .partial()
    .optional(),
  category: z
    .object({
      id: z.string().max(255),
      name: z.string().max(255).optional(),
    })
    .partial()
    .optional(),
  colors: z
    .array(
      z
        .object({
          id: z.string().max(255),
          name: z.string().max(255).optional(),
          value: z.string().max(255).optional(),
        })
        .partial()
        .optional(),
    )
    .optional(),
  tags: z
    .array(
      z
        .object({
          id: z.string().max(255),
          name: z.string().max(255).optional(),
          value: z.string().max(255).optional(),
        })
        .partial()
        .optional(),
    )
    .optional(),
  height: z.number().int().optional(),
  width: z.number().int().optional(),
  length: z.number().int().optional(),
  weight: z.number().int().optional(),
  backorder: z.boolean().optional(),
  thumbnail: z.object({ url: z.string().optional() }).partial().optional(),
  gallery: z
    .array(
      z
        .object({
          url: z.string().optional(),
        })
        .partial(),
    )
    .optional(),
  sizes: z
    .array(
      z
        .object({
          id: z.string().optional(),
          name: z.string().optional(),
          value: z.string().optional(),
        })
        .partial()
        .optional(),
    )
    .optional(),
  quantity: z
    .object({
      stock: z.object({
        value: z.number(),
      }),
      minimum: z
        .object({
          value: z.number().optional(),
        })
        .optional(),
      available: z
        .object({
          value: z.number().optional(),
        })
        .optional(),
    })
    .optional(),
  currency: z.string().optional(),
  price: z
    .object({
      public: z.object({
        currency: z.string().optional(),
        value: z.number(),
        factor: z.number().optional(),
      }),
      mayor: z.object({
        currency: z.string().optional(),
        value: z.number().optional(),
        factor: z.number().optional(),
      }),
      franchise: z.object({
        currency: z.string().optional(),
        value: z.number().optional(),
        factor: z.number().optional(),
      }),
    })
    .optional(),
  related: z
    .array(
      z
        .object({
          productId: z.string().optional(),
        })
        .partial()
        .optional(),
    )
    .optional(),
});

export default schemas;
