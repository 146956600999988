import { IImage } from '@/bundles/core';
import useInputGallery from '@/bundles/core/components/client/input-gallery/hooks/useInputGallery';
import { InputFieldProps } from '@/bundles/core/types';
import { DataTable } from '@lib/devias/components/core/data-table';
import { FileDropzone } from '@lib/devias/components/core/file-dropzone';
import { Box, Card, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import useImageColumns from './hooks/useImageColumns';

export type InputGalleryProps = { images?: never[]; onChange?: (arg: unknown) => void; label: string } & InputFieldProps;

const InputGallery: FC<InputGalleryProps> = (props) => {
  const { handleImageRemove, handleImageDrop, images, hasImages } = useInputGallery({ ...props });
  const { columns } = useImageColumns({ onRemove: (image: IImage) => handleImageRemove?.(image, images) });

  return (
    <Stack spacing={3}>
      <Typography variant="h6">{props.label}</Typography>
      <Card sx={{ borderRadius: 1 }} variant="outlined">
        {images?.filter(i=>i) && <DataTable<IImage> columns={columns} rows={images} />}
        {!hasImages ? (
          <Box sx={{ p: 1 }}>
            <Typography align="center" color="text.secondary" variant="body2">
              Sin imágenes
            </Typography>
          </Box>
        ) : null}
      </Card>
      <FileDropzone accept={{ 'image/*': [] }} caption="(SVG, JPG, PNG, or gif maximum 900x400)" onDrop={(files) => handleImageDrop?.(files)} multiple={props.multiple} />
    </Stack>
  );
};

export default InputGallery;
