import { z } from 'zod';

const schemas = z.object({
  name: z.string(),
  description: z.string(),
  icon: z.object({ url: z.string() }).partial(),
  public_key: z.string(),
  secret_key: z.string(),
});

export default schemas;
