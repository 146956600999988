import { zodResolver } from '@hookform/resolvers/zod';
import LOG from '@lib/logger';
import { useMemo } from 'react';
import { z } from 'zod';

import { PostFormProps } from '@/bundles/blog/components/client/post-form';
import { Post } from '@/bundles/blog/model/post';
import InputField from '@/bundles/core/components/client/input-field';
import InputSelect from '@/bundles/core/components/client/input-select';
import InputTextEditor from '@/bundles/core/components/client/input-text-editor';
import { IFormField } from '@/bundles/core/types';

import schemas from '../schema';

export type FormValuesProps = z.infer<typeof schemas>;
export type useFormFieldsProps = {} & PostFormProps;

const useFormFields = (props: useFormFieldsProps) => {
  const fields = useMemo(() => {
    const inputFields: Array<IFormField> = [
      [
        {
          name: 'title',
          label: 'Título del artículo',
          type: 'text',
          Component: InputField,
        },
        {
          name: 'postType',
          label: 'Tipo de publicación',
          type: 'select',
          Component: InputSelect,
          options: [
            { label: 'FAQ', value: 'faq' },
            { label: 'BLOG', value: 'blog' },
          ],
        },
        {
          name: 'status',
          label: 'Estado de publicación',
          type: 'select',
          Component: InputSelect,
          options: [
            { label: 'Pendiente', value: 'pendiente' },
            { label: 'Pausado', value: 'pausado' },
            { label: 'Publicado', value: 'publicado' },
          ],
        },
      ],
      {
        name: 'content',
        label: 'Contenido',
        type: 'text',
        multiline: true,
        Component: InputTextEditor,
      },
    ];
    return inputFields;
  }, []);

  const defaultValues = useMemo(() => {
    const defValues = (props.post ? new Post(props.post as Record<string, unknown>) : new Post({})) as FormValuesProps;
    LOG.debug({ msg: 'defaultValues', defValues, post: props.post });
    return defValues;
  }, [props.post]);

  const resolver = zodResolver(schemas);

  return { fields, defaultValues, resolver };
};

export default useFormFields;
