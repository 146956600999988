import { z } from 'zod';

const schemas = z.object({
  title: z.string().max(255),
  content: z.string(),
  postType: z.string(),
  status: z.string().optional(),
  thumbnail: z.object({ url: z.string() }).optional(),
});

export default schemas;
