import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, Suspense } from 'react';

import Breadcrumbs from '@/bundles/core/components/client/breadcrumbs';
import EntityManager from '@/bundles/core/services/entity-manager';
import { AppPageProps } from '@/bundles/core/types';
import CustomerForm from '@/bundles/customers/components/client/customer-form';
import paths from '@/bundles/customers/paths';

import { ICustomer } from '../../model/customer';

const API_PROXY_URL = process.env.API_PROXY_URL || '/';

const customerManager = new EntityManager<ICustomer>(API_PROXY_URL, '/api/customers');

const CreatePage: FC<AppPageProps> = async (props) => {
  const customer = (await customerManager.getById(props?.params?.id, {}))?.data as ICustomer;
  return (
    <Suspense>
      <Box sx={{ p: 5 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Breadcrumbs paths={[{ href: paths.index, name: 'Clientes' }]} />
            <Stack spacing={1}>
              <Typography variant="h4">{customer?.name ? customer?.name : 'Nuevo cliente'}</Typography>
              <Typography variant="body2">{customer?.createdAt ? `Ultima actualización: ${new Date(customer?.createdAt).toLocaleString()}` : ''}</Typography>
            </Stack>
          </Stack>
          <CustomerForm customer={customer} />
        </Stack>
      </Box>
    </Suspense>
  );
};

export default CreatePage;
