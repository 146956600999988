import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { Suspense } from 'react';

import Breadcrumbs from '@/bundles/core/components/client/breadcrumbs';
import EntityManager from '@/bundles/core/services/entity-manager';
import { IPaymethod } from '@/bundles/general/model/paymethod';
import paths from '@/bundles/general/paths';

import PaymethodForm from '../../components/client/paymethod-form';

const API_PROXY_URL = process.env.API_PROXY_URL || '/';

const paymethodManager = new EntityManager<IPaymethod>(API_PROXY_URL, '/api/paymethods');

const PaymethodPage = async () => {
  const paymethod = ((await paymethodManager.getAll({ limit: '1' }))?.data as IPaymethod[])?.at(0);

  return (
    <Suspense>
      <Box sx={{ p: 5 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Breadcrumbs
              paths={[
                { href: paths.index, name: 'General' },
                { href: paths.organization, name: 'Método de pagos' },
              ]}
            />
            <Stack spacing={1}>
              <Typography variant="h4">{paymethod?.name ? paymethod?.name : 'Métodos de pagos'}</Typography>
              <Typography variant="body2">{paymethod?.createdAt ? `Ultima actualización: ${new Date(paymethod?.createdAt).toLocaleString()}` : ''}</Typography>
            </Stack>
          </Stack>
          <PaymethodForm paymethod={paymethod} />
        </Stack>
      </Box>
    </Suspense>
  );
};

export default PaymethodPage;
