import { Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { ArrowLeft as ArrowLeftIcon } from '@phosphor-icons/react/dist/ssr/ArrowLeft';
import RouterLink from 'next/link';
import React, { FC } from 'react';

export type BreadcrumbsProps = {
  paths: { href: string; name: string }[];
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ paths }) => {
  return (
    <Stack spacing={1} direction={'row'}>
      {paths?.map((path, idx) => (
        <Link key={idx} color="text.primary" component={RouterLink} href={path.href} sx={{ alignItems: 'stretch', display: 'flex', gap: 1 }} variant="subtitle2">
          {idx == 0 && (
            <Box display={'flex'} alignItems={'center'}>
              <ArrowLeftIcon fontSize="var(--icon-fontSize-md)" />
            </Box>
          )}
          <Box>
            <Typography fontSize={'small'}>{path.name}</Typography>
          </Box>
          {idx < paths.length - 1 && <Box>/</Box>}
        </Link>
      ))}
    </Stack>
  );
};

export default Breadcrumbs;
