import { z } from "zod";

const schemas = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  keywords: z.string().optional(),
  theme: z.string().optional(),
  dangerousScriptHeader: z.string().optional(),
  dangerousScriptFooter: z.string().optional(),
  sendgridApiKey: z.string().optional(),
  sendgridFrom: z.string().optional(),
  googleClientId: z.string().optional(),
  googleClientSecret: z.string().optional(),
  facebookPixelId: z.string().optional(),
  favicon: z
    .object({
      url: z.string().optional()
    })
    .partial()
    .optional(),
  banners: z
    .array(
      z
        .object({
          url: z.string().optional()
        })
        .partial()
    )
    .optional()
});

export default schemas;
