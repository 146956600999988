import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { Trash as TrashIcon } from '@phosphor-icons/react/dist/ssr/Trash';
import React, { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { InputFieldProps } from '@/bundles/core/types';

export type InputArrayFieldProps = {} & InputFieldProps;

const InputArrayField: FC<InputArrayFieldProps> = (props) => {
  const fieldArray = useFieldArray({ control: props.control, name: props.name });
  return (
    <Stack spacing={1}>
      <Stack spacing={1} flexDirection="row" alignItems="center">
        <Typography variant="h6">{props.label}</Typography>
        <Button onClick={() => fieldArray.append(props.defaultArrayItemValue)}>Agregar</Button>
      </Stack>
      <Stack spacing={1}>
        {fieldArray.fields.map((field, idx) => {
          if (!props.entityField) return null;
          const name = `${props.name}.${idx}.${props.entityField?.name}`;
          const arrayIndex = `${props.name}.${idx}`;

          const Component = props.entityField?.Component || (() => null);
          // LOG.debug({ msg: 'InputArrayField', name, defaultItemValue: props.defaultArrayItemValue, entityField: props.entityField });
          return (
            <Stack key={idx} spacing={1} flexDirection="row" alignItems="stretch">
              <Box>
                <Component {...props.entityField} arrayIndex={arrayIndex} name={name} form={props.form} control={props.control} />
              </Box>
              <Stack flexDirection="row" alignItems="flex-end" sx={{}}>
                <Button onClick={() => fieldArray.remove(idx)} sx={{ bottom: 5 }}>
                  <TrashIcon />
                </Button>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default InputArrayField;
