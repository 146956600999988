import { Box, Stack } from '@mui/system';
import { Image as ImageIcon } from '@phosphor-icons/react/dist/ssr/Image';
import { FC, ReactNode } from 'react';

type ImageFormatterType = {
  index: string;
  row: any;
  children?: ReactNode;
};

const ImageFormatter: FC<ImageFormatterType> = ({ index, row, children }) => {
  const thumbnail = Array.isArray(row?.[index]) ? row?.[index][0] : row?.[index];
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      {thumbnail ? (
        <Box
          sx={{
            alignItems: 'center',
            bgcolor: 'var(--mui-palette-background-level2)',
            backgroundImage: `url(${thumbnail})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderRadius: 1,
            display: 'flex',
            height: '80px',
            justifyContent: 'center',
            overflow: 'hidden',
            width: '80px',
          }}
        />
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            bgcolor: 'var(--mui-palette-background-level2)',
            borderRadius: 1,
            display: 'flex',
            height: '80px',
            justifyContent: 'center',
            width: '80px',
          }}
        >
          <ImageIcon fontSize="var(--icon-fontSize-lg)" />
        </Box>
      )}
      <div>
        {children}
        {/* <MLink
          href={`/products/${row.id}`}
          component={Link}
          color="text.primary"
          sx={{ whiteSpace: "nowrap" }}
          underline="none"
          variant="subtitle2"
        >
          {row.name}
        </MLink>
        <Typography color="text.secondary" variant="body2">
          in {row.categoryId}
        </Typography> */}
      </div>
    </Stack>
  );
};
export default ImageFormatter;
