import type { Viewport } from 'next';
import * as React from 'react';
import { Toaster } from 'sonner';

import { Analytics } from './components/core/analytics';
import { I18nProvider } from './components/core/i18n-provider';
import { LocalizationProvider } from './components/core/localization-provider';
import { SettingsButton } from './components/core/settings/settings-button';
import { ThemeProvider } from './components/core/theme-provider/theme-provider';
import { UserProvider } from './contexts/auth/user-context';
import { SettingsProvider } from './contexts/settings';

export const viewport = {
  width: 'device-width',
  initialScale: 1,
  themeColor: '#000000',
} satisfies Viewport;

interface LayoutProps {
  children?: React.ReactNode;
  settings?: any;
  settingsBtn?: boolean;
}

export default function DeviasProvider({ children, settings, settingsBtn }: LayoutProps) {
  return (
    <Analytics>
      <LocalizationProvider>
        <UserProvider>
          <SettingsProvider settings={settings}>
            <I18nProvider language="es">
              <ThemeProvider>
                <>
                  {children}
                  {settingsBtn && <SettingsButton />}
                  <Toaster position="bottom-right" />
                </>
              </ThemeProvider>
            </I18nProvider>
          </SettingsProvider>
        </UserProvider>
      </LocalizationProvider>
    </Analytics>
  );
}
