import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, Suspense } from 'react';

import Breadcrumbs from '@/bundles/core/components/client/breadcrumbs';
import EntityManager from '@/bundles/core/services/entity-manager';
import { AppPageProps } from '@/bundles/core/types';
import paths from '@/bundles/customers/paths';
import UserForm from '@/bundles/users/components/client/user-form';

import { IUser } from '../../model/user';

const API_PROXY_URL = process.env.API_PROXY_URL || '/';

const userManager = new EntityManager<IUser>(API_PROXY_URL, '/api/users');

const CreatePage: FC<AppPageProps> = async (props) => {
  const user = (await userManager.getById(props?.params?.id, {}))?.data as IUser;
  return (
    <Suspense>
      <Box sx={{ p: 5 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Breadcrumbs paths={[{ href: paths.index, name: 'Usuarios' }]} />
            <Stack spacing={1}>
              <Typography variant="h4">{user?.name ? user?.name : 'Nuevo usuario'}</Typography>
              <Typography variant="body2">{user?.createdAt ? `Ultima actualización: ${new Date(user?.createdAt).toLocaleString()}` : ''}</Typography>
            </Stack>
          </Stack>
          <UserForm user={user} />
        </Stack>
      </Box>
    </Suspense>
  );
};

export default CreatePage;
