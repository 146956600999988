import type { NavItemConfig } from '@lib/devias/types/nav';

import * as blog from '../blog';
import * as customers from '../customers';
import * as analytics from '../dashboard';
import * as settings from '../general';
import * as products from '../products';
import * as users from '../users';

export interface LayoutConfig {
  navItems: NavItemConfig[];
}

export let layoutConfig = {
  navItems: [],
} satisfies LayoutConfig;

layoutConfig.navItems = layoutConfig.navItems
  .concat(analytics.Menu.navItems as typeof layoutConfig.navItems)
  .concat(products.Menu.navItems as typeof layoutConfig.navItems)
  .concat(blog.Menu.navItems as typeof layoutConfig.navItems)
  .concat(customers.Menu.navItems as typeof layoutConfig.navItems)
  .concat(users.Menu.navItems as typeof layoutConfig.navItems)
  .concat(settings.Menu.navItems as typeof layoutConfig.navItems);
export default layoutConfig;
