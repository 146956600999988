import { useEffect } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { FormValuesProps } from './useFormFields';

const usePriceHandler = (watch: UseFormWatch<FormValuesProps>, setValue: Function) => {
  const publicPrice = watch('price.public.value');
  const mayorFactor = watch('price.mayor.factor');
  const franchiseFactor = watch('price.franchise.factor');

  useEffect(() => {
    if (publicPrice) {
      if (mayorFactor) setValue('price.mayor.value', publicPrice * mayorFactor);
      if (franchiseFactor) setValue('price.franchise.value', publicPrice * franchiseFactor);
    }
  }, [publicPrice, mayorFactor, franchiseFactor]);

  return;
};

export default usePriceHandler;
