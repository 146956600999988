import { FC, ReactNode } from 'react';

import styles from './style.module.css';

type CircleSpinnerType = {
  children?: ReactNode;
};
const CircleSpinner: FC<CircleSpinnerType> = ({}) => {
  return (
    <div
      style={{
        position: 'relative',
        zIndex: 200,
        width: '100%',
        height: '100%',
        // top: 0,
        // left: 0,
        background: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span className={styles['loader']}></span>
    </div>
  );
};
export default CircleSpinner;
