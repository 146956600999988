import { zodResolver } from '@hookform/resolvers/zod';
import LOG from '@lib/logger';
import { useMemo } from 'react';
import { z } from 'zod';

import InputField from '@/bundles/core/components/client/input-field';
import InputSelect from '@/bundles/core/components/client/input-select';
import { IFormField } from '@/bundles/core/types';
import { Customer } from '@/bundles/customers/model/customer';

import schemas from '../schema';

export type FormValuesProps = z.infer<typeof schemas>;

const useFormFields = (props: any) => {
  const fields = useMemo(() => {
    const inputFields: Array<IFormField> = [
      [
        {
          name: 'name',
          label: 'Nombre del cliente',
          type: 'text',
          autoComplete: 'username',
          Component: InputField,
        },
        {
          name: 'role',
          label: 'Tipo de cliente',
          type: 'select',
          Component: InputSelect,
          options: [
            { label: 'Consumidor final', value: 'consumidor' },
            { label: 'Franquicia', value: 'franquicia' },
            { label: 'Mayorista', value: 'mayorista' },
          ],
        },
        {
          name: 'status',
          label: 'Estado',
          type: 'select',
          Component: InputSelect,
          options: [
            { label: 'Pendiente', value: 'pendiente' },
            { label: 'Suspendido', value: 'suspendido' },
            { label: 'Activo', value: 'activo' },
          ],
        },
      ],
      {
        name: 'username',
        label: 'Usuario',
        autoComplete: 'username',
        type: 'text',
        Component: InputField,
      },
      {
        name: 'email',
        label: 'Correo electrónico',
        type: 'email',
        autoComplete: 'email',
        Component: InputField,
      },
      [
        {
          name: 'password',
          label: 'Clave',
          type: 'password',
          autoComplete: 'new-password',
          Component: InputField,
        },
        {
          name: 'confirm',
          label: 'Confirmar Clave',
          autoComplete: 'new-password',
          type: 'password',
          Component: InputField,
        },
      ],
    ];
    return inputFields;
  }, []);

  const defaultValues = useMemo(() => {
    const defValues = (props.customer ? new Customer(props.customer as Record<string, unknown>) : new Customer({})) as FormValuesProps;
    LOG.debug({ msg: 'defaultValues', defValues, customer: props.customer });
    return defValues;
  }, [props.customer]);

  const resolver = zodResolver(schemas);

  return { fields, defaultValues, resolver };
};

export default useFormFields;
