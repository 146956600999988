import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { Suspense } from 'react';

import Breadcrumbs from '@/bundles/core/components/client/breadcrumbs';
import paths from '@/bundles/general/paths';

const API_PROXY_URL = process.env.API_PROXY_URL || '/';

// const settingManager = new EntityManager<IShop>(API_PROXY_URL, '/api/shops');

const SettingsPage = async () => {
  const setting = {} as any; //((await settingManager.getAll({ limit: '1' }))?.data as IShop[])?.at(0);

  return (
    <Suspense>
      <Box sx={{ p: 5 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Breadcrumbs
              paths={[
                { href: paths.index, name: 'General' },
                { href: paths.organization, name: 'Ajustes' },
              ]}
            />
            <Stack spacing={1}>
              <Typography variant="h4">{setting?.title ? setting?.title : 'Configuraciones'}</Typography>
              <Typography variant="body2">{setting?.createdAt ? `Ultima actualización: ${new Date(setting?.createdAt).toLocaleString()}` : ''}</Typography>
            </Stack>
          </Stack>
          {/*<ShopForm shop={setting} />*/}
        </Stack>
      </Box>
    </Suspense>
  );
};

export default SettingsPage;
