import { useFlash } from '@lib/flash';
import LOG from '@lib/logger';
import { objEmpty } from '@lib/utils';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import EntityManager from '@/bundles/core/services/entity-manager';
import { IOrganization, Organization } from '@/bundles/general/model/organization';

import { OrganizationFormProps } from '../index';
import useFormFields, { FormValuesProps } from './useFormFields';

export type useOrganizationFormProps = {} & OrganizationFormProps;

export default function useOrganizationForm(props: useOrganizationFormProps) {
  const flash = useFlash();
  const { fields, defaultValues, resolver } = useFormFields(props);
  const { control, handleSubmit, ...form } = useForm<FormValuesProps>({ defaultValues, resolver });

  const onSubmit = async (values: FormValuesProps) => {
    try {
      LOG.debug({ msg: 'onSubmit', values });
      const organizationManager = new EntityManager<IOrganization>('/', '/api/proxy/organizations');
      const newOrganization = new Organization({ ...values }).toFormData();
      const { error } = await organizationManager.save({ ...newOrganization, id: props?.organization?.id });
      if (error) throw new Error(error);
      flash.toast('Datos guardados con éxitos!', 'info');
    } catch (error) {
      LOG.warn({ msg: 'onSubmit', error });
      flash.toast('No pudimos guardar los datos', 'error', (error as Error).message);
    }
  };

  useEffect(() => {
    if (!objEmpty(form.formState.errors)) {
      const error = JSON.stringify(form.formState.errors, null, 2);
      flash.toast('Hay errores en el formulario', 'error', error);
      LOG.warn({ msg: 'useCustomerForm::formState.errors', errors: form.formState.errors });
    }
  }, [form.formState.errors]);

  return { onSubmit, handleSubmit, control, form, fields };
}
