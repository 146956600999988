import { IImage, Image } from '@/bundles/core';
import { BaseEntity, IBaseEntity } from '@/bundles/core/model';

export interface IDataFiscal extends IBaseEntity {
  url?: string;
  code?: string;
  qr?: string;
}

export interface IOrganization extends IBaseEntity {
  name?: string;
  cuit?: string;
  logo?: IImage;
  dataFiscal?: IDataFiscal;
}

export class DataFiscal extends BaseEntity implements IDataFiscal {
  url?: string = '';
  code?: string = '';
  qr?: string = '';

  constructor(data: Record<string, unknown> & IDataFiscal) {
    super(data);
    this.url = (data?.url as string) || this.url;
    this.code = (data.code as string) || this.code;
    this.qr = (data.qr as string) || this.qr;
  }
}

export class Organization extends BaseEntity implements IOrganization {
  name?: string = '';
  cuit?: string = '';
  logo?: IImage = new Image({ url: '' });
  dataFiscal?: IDataFiscal = new DataFiscal({ url: '', code: '', qr: '' });

  constructor(data: Record<string, unknown> & IOrganization) {
    super(data);
    this.name = data?.name || this.name;
    this.cuit = data?.cuit || this.cuit;
    this.logo = data?.logo ? new Image(data.logo as Record<string, unknown>) : this.logo;

    this.dataFiscal = new DataFiscal({
      url: data?.dataFiscal?.url || '',
      code: data?.dataFiscal?.code || '',
      qr: data?.dataFiscal?.qr || '',
    });
  }
}
