import { IImage, Image } from '@/bundles/core';
import { InputGalleryProps } from '@/bundles/core/components/client/input-gallery';
import LOG from '@lib/logger';
import { fileToBase64 } from '@lib/utils';
import { useEffect, useMemo, useState } from 'react';

export type UseInputGalleryProps = { onChange?: (arg: unknown) => void; images?: never[] } & InputGalleryProps;

const useInputGallery = (props: UseInputGalleryProps) => {
  const { form } = props;

  const [images, setImages] = useState<IImage[]>([]);

  const gallery = (form as { watch: Function })?.watch?.(props.name);

  const setGallery = (newGallery: IImage[]) => {
    if (props.multiple) (form as { setValue: Function })?.setValue(props.name, newGallery);
    else (form as { setValue: Function })?.setValue(props.name, newGallery[0]);
    LOG.debug({ msg: 'InputGallery:setGallery', newGallery });
  };

  const handleImageDrop = async (files: File[]) => {
    let newImages = await Promise.all(
      files.map(
        async (file) =>
          new Image({
            fileName: file.name,
            url: await fileToBase64(file),
            objectUrl: URL.createObjectURL(file)
          })
      )
    );

    LOG.debug({ msg: 'InputGallery:handleImageDrop:newImages', newImages });

    if (Array.isArray(gallery)) newImages = [...gallery, ...newImages];
    else newImages.push(gallery);

    const newGallery = [...newImages]?.filter(i=>i)
    setGallery(newGallery);
    setImages(newGallery);
  };

  const handleImageRemove = (file: IImage, images: IImage[]) => {
    LOG.debug({ msg: 'InputGallery:handleImageRemove', file, images, newGallery: [] });
    const newGallery = images.filter((image) => image.id !== file.id);
    LOG.debug({ msg: 'InputGallery:handleImageRemove', file, images, newGallery });
    setGallery(newGallery);
    setImages(newGallery);
  };

  const hasImages = useMemo(() => images.length > 0, [images]);

  // cuando se carga el formulario con un valor inicial
  useEffect(() => {
    if (gallery) {
      const images = Array.isArray(gallery) ? gallery : [gallery];
      setImages(images);
      LOG.debug({ msg: 'InputGallery:useEffect', images });
    }
  }, []);

  return { handleImageDrop, handleImageRemove, images, hasImages };
};

export default useInputGallery;
