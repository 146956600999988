import { NavItem } from '@lib/devias/lib/menu';

import { LayoutConfig } from '../core/menu';

const menu: NavItem = {
  key: 'general',
  title: 'General',
  items: [
    {
      key: 'sites',
      title: 'Tienda',
      icon: 'shopping-bag-open',
      href: '/admin/general/shop',
    },
    {
      key: 'organization',
      title: 'Organización',
      icon: 'read-cv-logo',
      href: '/admin/general/organization',
    },
    {
      key: 'payments',
      title: 'Métodos de Pago',
      icon: 'credit-card',
      href: '/admin/general/paymethod',
    },
    // {
    //   key: 'general',
    //   title: 'Ajustes',
    //   icon: 'gear',
    //   href: '/admin/general'
    // }
  ],
};

export default { navItems: [new NavItem(menu)] } satisfies LayoutConfig;
