import useFields, { FormValuesProps } from '@/bundles/products/components/client/product-form/hooks/useFormFields';
import usePriceHandler from '@/bundles/products/components/client/product-form/hooks/usePriceHandler';
import { ICategory } from '@/bundles/products/model/category';
import { ICollection } from '@/bundles/products/model/collection';
import { IColor } from '@/bundles/products/model/color';
import { ILine } from '@/bundles/products/model/line';
import { IProduct } from '@/bundles/products/model/product';
import { ISize } from '@/bundles/products/model/size';
import ProductManager from '@/bundles/products/services/product-manager';
import { useFlash } from '@lib/flash';
import LOG from '@lib/logger';
import { objEmpty } from '@lib/utils';
import { useRouter } from 'next/navigation';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

export type ProductFormType = {
  product: IProduct | null;
  collections: ICollection | null;
  lines: ILine | null;
  categories: ICategory | null;
  colors: IColor | null;
  sizes: ISize | null;
};

export function useProductForm(props: ProductFormType) {
  const router = useRouter();
  const flash = useFlash();
  const { fields, defaultValues, resolver } = useFields(props);
  const { control, handleSubmit, ...form } = useForm<FormValuesProps>({ defaultValues, resolver });

  const productManager = useMemo(() => new ProductManager('/', '/api/proxy/products'), []);

  usePriceHandler(form.watch, form.setValue);

  const onSubmit = async (values: FormValuesProps) => {
    try {
      LOG.debug({ msg: 'onSubmit', values });
      const { error, data: product } = await productManager.save({ ...values, id: props.product?.id });
      LOG.debug({ msg: 'onSubmit:products', product, error });
      if (error) flash.toast('No pudimos guardar el producto', 'error', error);
      else {
        flash.toast('Producto guardado', 'success');
        router.refresh();
      }
    } catch (error) {
      LOG.warn({ msg: 'onSubmit', error });
      flash.toast('No pudimos guardar el producto', 'error', (error as Error).message);
    }
  };

  useEffect(() => {
    if (!objEmpty(form.formState.errors)) {
      const error = JSON.stringify(form.formState.errors, null, 2);
      flash.toast('Hay errores en el formulario', 'error', error);
      LOG.warn({ msg: 'formState.errors', errors: form.formState.errors });
    }
  }, [form.formState.errors]);

  return { onSubmit, handleSubmit, control, form, fields };
}
