import CircleSpinner from '@lib/loaders/components/circle';
import { FC, ReactNode, useState } from 'react';

import { Provider } from '../context';

export type LoaderProviderProps = { children: ReactNode };

const LoaderProvider: FC<LoaderProviderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Provider value={{ isLoading, setLoading }}>
      {children}
      {isLoading && <CircleSpinner />}
    </Provider>
  );
};

export default LoaderProvider;
